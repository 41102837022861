<template>
  <v-row>
    <v-col class="d-flex justify-center">
      <v-card class="mx-auto mb-5">
        <v-card-title>Analyses à exporter</v-card-title>
        <v-list>
          <v-list-item-group v-model="options" multiple>
            <template v-for="(item, i) in items">
              <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>
              <v-list-item
                v-else
                :key="`item-${i}`"
                :value="item.value"
                :disabled="item.disabled"
                >
                <template v-slot:default="{ active }">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                    <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-checkbox
                      :input-value="active"
                    ></v-checkbox>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
        <v-card-text>Formats disponibles
        <br/>
        <v-progress-linear v-show="loading"
          indeterminate
          color="primary">
        </v-progress-linear>
      </v-card-text>

        <v-card-actions class="justify-space-around d-flex">
          <v-btn class="mb-2 flex-grow-1" color="red"
          :disabled="options.length == 0" outlined @click="pdf()">
            <v-icon>mdi-file-pdf</v-icon> PDF
          </v-btn>
          <v-btn class="mb-2 flex-grow-1" color="blue"
          :disabled="options.length == 0" outlined @click="json()">
            <v-icon>mdi-code-braces</v-icon> JSON
          </v-btn>

          <v-btn class="mb-2 flex-grow-1" color="green"
          :disabled="options.length == 0" outlined @click="latex()">
            <v-icon>mdi-file-document</v-icon> LaTeX
          </v-btn>

          <v-btn class="mb-2 flex-grow-1" color="purple"
          :disabled="options.length == 0" outlined @click="text()">
            <v-icon>mdi-format-text</v-icon> Texte
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import MixinInterviews from '@/mixins/interviews';

export default {
  name: 'Export',
  mixins: [MixinInterviews],
  props: {
    patientId: String,
    interviewId: String,
  },
  data: () => ({
    loading: false,
    options: [],
    items: [
      { value: '--raw-basics', title: 'Données brutes', description: 'Nombre de tours, mots, nombre moyen mots/tour, longueur moyenne des mots, ratio stopwords' },
      { value: '--raw-advanced', title: 'Données brute avancées', description: 'Évolution de la longueur des mots, nombre de lemmes/formes, POS-Tag' },
      { value: '--syntax-basics', title: 'Syntaxe', description: 'Densité lexicale, nombre de question, subordonnées, arité verbale' },
      { value: '--syntax-advanced', title: 'Syntaxe avancée', description: 'Données sur les relations syntaxiques' },
      { value: '--disfluences', title: 'Disfluences', description: 'Compte les disfluences' },
      { value: '--deictics', title: 'Déictiques', description: 'Compte les déictiques' },
      { value: '--dialogic', title: 'Analyse dialogique', description: 'Transactions, questions/réponses' },
      { value: '--discursive', title: 'Analyse discursive', disabled: true },
    ],
  }),
  methods: {
    download(name, data, type) {
      const url = URL.createObjectURL(new Blob([data]), { type });
      const link = document.createElement('a');
      link.href = url;
      link.download = name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },

    async pdf() {
      this.loading = true;
      const data = await this.getReport(this.patientId, this.interviewId, 'pdf', this.options);
      const buf = data.substring(1).replaceAll('\'', '');
      this.download('report.pdf', atob(buf), 'application/pdf');
      this.loading = false;
    },

    async text() {
      this.loading = true;
      const data = await this.getReport(this.patientId, this.interviewId, 'txt', this.options);
      this.download('report.txt', data, 'application/txt');
      this.loading = false;
    },

    async latex() {
      this.loading = true;
      const data = await this.getReport(this.patientId, this.interviewId, 'latex', this.options);
      this.download('report.tex', data, 'application/txt');
      this.loading = false;
    },

    async json() {
      this.loading = true;
      const data = await this.getReport(this.patientId, this.interviewId, 'json', this.options);
      this.download('report.json', JSON.stringify(data, null, ' '), 'application/json');
      this.loading = false;
    },
  },
};
</script>
